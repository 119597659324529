.bg-dark {
    background: #000 !important;
}   

.card-boxshadow-hover:hover {
    -webkit-box-shadow: 0px 8px 20px -5px rgba(204,204,204,1);
-moz-box-shadow: 0px 8px 20px -5px rgba(204,204,204,1);
box-shadow: 0px 8px 20px -5px rgba(204,204,204,1);
transition: all .5s ease-in-out;
}

.card-boxshadow-hover {
transition: all .5s ease-in-out;
-webkit-box-shadow: 0px 3px 5px 0px rgba(204,204,204,1);
-moz-box-shadow: 0px 3px 5px 0px rgba(204,204,204,1);
box-shadow: 0px 3px 5px 0px rgba(204,204,204,1);
}

.headding-status::before {
    content: "";
    display: inline-block;
    border: 3px soild orange;
    background: orange;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.description {
    font-weight: normal;
}

.card-random {
    height: 180px;
}